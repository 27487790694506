<template>
  <div class="h-full" ref="innercontent" v-if="loaded">
    <div ref="header" name="header" class="px-4 shadow relative">
      <div class="flex content-end justify-between">
        <div>
          <h3 class="text-lg font-semibold pt-2 pb-1">fooooood</h3>
        </div>
      </div>
      <!-- <div class="absolute top-0 right-0">
        <div
          class="mt-12 mr-8 py-2 text-sm font-thin bg-yellow-200 border-r-4 rounded-sm border-yellow-400 p-2"
        >
          <slot name="info"></slot>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
import {
  // ValidationProvider,
  // ValidationObserver,
  // localize,
  extend,
} from "vee-validate";

import { required, oneOf, min_value, max_value, between } from "vee-validate/dist/rules";

extend("required", { ...required });
extend("oneOf", { ...oneOf });
extend("min_value", { ...min_value });
extend("max_value", { ...max_value });
extend("between", { ...between });

export default {
  name: "TherapyForm",
  components: {
    // ValidationProvider,
  },
  props: {
    therapy: {
      type: Object,
    },
  },
  data() {
    return {
      loaded: true,
    };
  },
  created() {
  },
  methods: {
  },
  computed: {
    administration() {
      return this.mapOptions("select_administration");
    },
    schedule() {
      return this.mapOptions("select_schedule");
    },
    unit() {
      return this.mapOptions("select_unit");
    },
  },
};
</script>
