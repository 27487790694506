<template>
  <div v-if="loaded" class="h-full w-full" ref="gridcontainer">
    <table class="sticky-header w-full text-xs">
      <thead ref="tableheader">
        <tr>
          <th :colspan="tab.structure ? tab.structure.length : 2">
            <div class="py-1 px-2 bg-gray-700 text-white flex justify-between">
              <div>
                <span class="mr-2">{{ tab.translation ? tab.translation.label : " - " }}</span>
                <cm-button @click="showForm(null)" size="small" visual="success">{{ $t("button.create") }}</cm-button>
              </div>

              <div>{{ $tc("module.tab.entries", therapies ? therapies.length : 0) }}</div>
            </div>
          </th>
        </tr>
        <tr>
          <th
            v-for="(header, key) in tab.structure"
            :key="key"
            class="py-1 cursor-pointer bg-white text-gray-800 border-b border-gray-300 hover:bg-gray-200 truncate"
            @click="setSort(header.name)"
          >
            <div class="flex items-center">
              <a href="#" slot="reference" class="top">
                <span class="px-1 mr-1 whitespace-nowrap">{{ header.translation.label }}</span>
              </a>

              <span v-show="header.name == sortcolumn">
                <svg
                  v-if="sortasc"
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  class="w-4 h-4"
                >
                  <path d="M5 10l7-7m0 0l7 7m-7-7v18" />
                </svg>
                <svg
                  v-if="!sortasc"
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  class="w-4 h-4"
                >
                  <path d="M19 14l-7 7m0 0l-7-7m7 7V3" />
                </svg>
              </span>
            </div>
          </th>
        </tr>
      </thead>

      <tbody v-if="tab" :style="dynamicGridHeight" ref="tablebody">
        <tr
          v-for="(row, key) in sortedData"
          :key="key"
          class="group odd:bg-gray-100 first:border-t border-b border-gray-200"
          @click="showForm(row.id)"
          :class="isHoveredOnGraph(row.id)"
        >
          <td
            class="px-2 align-top group-hover:bg-blue-100 group-hover:cursor-pointer border-transparent whitespace-nowrap"
            v-for="(header, key) in tab.structure"
            :key="key"
            :class="isHoveredOnGraph(row.id, header.name)"
          >
            {{ header.type === 'date' && row[header.name] ? formatDate(row[header.name]) : row[header.name] }}
          </td>
        </tr>
      </tbody>

      <tbody v-else>
        <tr>
          <td :colspan="tabcolumns.length">
            <div class="flex justify-center content-center pt-6">
              <div class="text-center">
                <!-- <font-awesome-icon :icon="'box-open'" class="text-5xl text-gray-400"></font-awesome-icon> -->
                <div>{{ $t("module.post.empty") }}</div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <modal
      name="therapy-form"
      :click-to-close="false"
      :scrollable="false"
      :resizable="false"
      :draggable="true"
      :adaptive="true"
      :reset="true"
      :pivotY="0.1"
      :height="'auto'"
      classes="modal-container bg-white rounded-md shadow-md transform transition-all object-contain"
    >
      <therapy-form :therapy="therapyData" />
    </modal>
  </div>
</template>

<script>
import { mapState } from "vuex"
// import { sortByColumn } from "@/utils/grid.js"
import TherapyForm from "./TherapyForm"
import { dateFormat } from "@/utils/date"

export default {
  name: "TherapyGrid",
  props: ["height"],
  components: {
    TherapyForm
  },
  data: () => ({
    loaded: false,
    therapyData: null,
    sortcolumn: null,
    sortasc: false,
    tabData: null,
    gridHeight: null,
    variableMeta: [],
  }),
  created() {
    this.load()
  },
  methods: {
    async load() {
      // await this.$store.dispatch("patient/therapyStructure")
      // await this.$store.dispatch("patient/therapyLayout")
      await this.$store.dispatch("moduledata/load", "therapy")

      this.loaded = true
    },
    formatDate (date) {
      return dateFormat(date)
    },
    isHoveredOnGraph(id, varname) {
      return {
        "bg-orange-300 text-gray-700": this.hoveredId === id,
        "font-semibold text-gray-900": this.hoveredId === id && this.hoveredVarname === varname,
      }
    },
    dynamicHeight() {
      this.$nextTick(() => {
        const th = this.$refs.tableheader ? this.$refs.tableheader.clientHeight : 50

        this.gridHeight = { height: this.height - th + "px" }
      })
    },
    async showForm(rowid) {
      const rowdata = rowid ? this.therapies.find(d => +d.id == +rowid) : null
      rowdata
      // if (rowdata) {
      //   await this.$store.dispatch("moduledata/setFormdata", { formname: this.name, data: rowdata })
      // } else {
      //   await this.$store.dispatch("moduledata/resetFormdata", this.name)
      // }

      this.$modal.show("therapy-form")
      // this.$modal.show("therapy-form", {
      //   data: rowdata,
      //   id: rowid,
      // })
    },
  },
  computed: {
    ...mapState("moduledata", {
      data: state => state.data,
      layout: state => state.layout.therapy,
      tab: state => state.tab.therapy,
      form: state => state.form,
    }),
    ...mapState("patient", {
      therapies: state => state.therapies,
    }),
    // ...mapState("moduledata", {
    //   data: state => state.therapydata,
    // }),
    dynamicGridHeight() {
      return this.gridHeight
    },
    sortedData() {
      return this.therapies
      // if (!this.sortcolumn) return this.therapies

      // const variableInfo = this.tab.structure.find(d => d.name === this.sortcolumn)

      // if (!variableInfo) return this.therapies

      // const columnType = variableInfo.type

      // return sortByColumn(JSON.parse(JSON.stringify(this.therapies)), this.sortcolumn, columnType, this.sortasc)
    },
  },
  watch: {
    therapies() {
      this.sortasc = false
      // this.setSort("drug")
      this.sortcolumn = null
    },
    height: {
      immediate: false,
      handler: "dynamicHeight",
    },
  },
}
</script>

<style>
.striped tr > td {
  background-color: inherit;
  border-color: inherit;
}
.sticky-header tbody {
  display: block;
  overflow-y: scroll;
}
.sticky-header thead,
.sticky-header tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
</style>
